<template>
    <seccion-datos
        :titulo="destinatarios"
        :botonGuardar="false"
    >
        <div v-if="mails">
            <div class="row">
                <div class="col-md-5">
                    <div class="input-group">                                                
                        <input 
                        type="text" 
                        class="form-control " 
                        :id="'email-destinatario-cia-' + this.$route.params.id" 
                        placeholder="Email"
                        v-model="emailNuevo"
                        >
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="input-group">                                                
                        <select 
                            class="custom-select form-control-sm" 
                            v-if="tipos"
                            v-model="tipoNuevo"
                        >
                            <option 
                                v-for="(tipo, i) in tipos" 
                                :key="'mail-destinatario-tipos-cia-' + this.$route.params.id + '-' + i"
                                :value="i"
                            >{{ tipo }}</option> 
                        </select>
                    </div>
                </div>
                <div class="col-md-1">
                    <button 
                        class="btn btn-primary"
                        @click="agregar"
                    >{{ $t('general.anadir') }}</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-sm table-striped" v-if="mails">
                        <tbody>
                            <tr
                                v-for="mail in mails" 
                                :data-key="'mail-destinatario-cia-' + this.$route.params.id + '-' + mail.email" 
                                :key="'mail-destinatario-cia-' + this.$route.params.id + '-' + mail.email"
                            >
                                <td>{{ tipo(mail.tipo) }}</td>
                                <td>{{ mail.email }}</td>
                                <td>
                                    <button 
                                        type="button" 
                                        class="btn btn-block btn-outline-danger btn-xs"
                                        @click="eliminar(mail)"
                                    >{{ $t('general.eliminar') }}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </seccion-datos>
</template>

<script>
export default {
    inject: ['agregarEmailCompanyia', 'eliminarEmailCompanyia'],
    props: {
        mails: {
            type: Array,
        },
        tipos: {
            type: Array,
        }
    },
    data() {
        return {
            emailNuevo: null,
            tipoNuevo: null,
            destinatarios: 'Destinatarios'
        }
    },
    methods: {
        tipo(tipo) {
            return this.tipos[tipo];
        },
        async agregar() {
            const datos = {
                email: this.emailNuevo,
                id_tipo: this.tipoNuevo, 
                remitente: 0
            };
            return await this.agregarEmailCompanyia(datos);
        },
        async eliminar(mail) {
            if (! confirm('Desea quitar este email?')) {
                return false;
            }
            const datos = {
                correo: mail.email,
                tipo: mail.tipo,
                remitente: 0,
            }
            return await this.eliminarEmailCompanyia(datos);
        }
    },
    mounted() { 
        this.destinatarios = this.$t('general.destinatarios');
    }
}
</script>

<style scoped>
table {
    margin-top: 10px;
}
</style>